<template>
	<div style="background: #f1f1f1;">
		<div class="flex nav flex-row-around">
			<div class="blocks">
				<div class="yellow">LV:{{userInfo.vip}}</div>
				<div class="green">{{userInfo.designPoints}}</div>
				<div class="gray">({{$t("newadd.t.当前会员积分")}} )  </div>
				<div class="" @click="centerDialogVisible=true">{{$t("newadd.t.生成二维码")}} 
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>
			<div class="blocks">
				<div style="visibility:hidden;">{{userInfo.giftVoucher}}</div>
				<div class="green">{{userInfo.giftVoucher}}</div>
				<div class="gray">({{$t("newadd.t.礼品券值")}} )</div>
				<div @click="go_gifts">{{$t("newadd.t.兑换礼品")}} 
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>
		</div>

		<!-- 商品信息 -->
		<el-table :data="tableData" style="width: 100%;margin-top: 20px;">
			<el-table-column prop="giftName" :label="$t('newadd.t.礼物名称')">
			</el-table-column>
			<el-table-column prop="giftModel"  :label="$t('newadd.t.礼品型号')">
			</el-table-column>
			<el-table-column prop="address" :label="$t('newadd.t.默认地址')">
			</el-table-column>
		</el-table>
		
		
		
		<el-dialog
		  :title= "$t('inviteCode.mesAlertTitle')"
		  :visible.sync="centerDialogVisible"
		  width="20%"
		  center>
		  <span>{{$t("inviteCode.mesAlertContent")}}</span>
		  <div style="text-align: center;margin-top: 20px;font-size: 20px; ">
			{{userInfo.inviteCode}}
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="centerDialogVisible = false">{{$t("inviteCode.mesAlertConfirm")}}</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import  {mapState} from "vuex";
	export default {
		data() {
			return {
				centerDialogVisible:false,
				info: {
					level: "5454"

				},
				tableData: []
			}
		},
		computed: {
			...mapState(["userInfo"])
		},
		created(){
			this.get_list()
		},
		methods: {
			open() {
				this.$alert(
				    this.$t("inviteCode.mesAlertContent"),
				    this.$t("inviteCode.mesAlertTitle"),
				    {
				      confirmButtonText: this.$t("inviteCode.mesAlertConfirm"),
				      callback: (action) => {
				      },
				    }
				);
				
			
			},
			get_list(){
				this.$req.get("/client/im/user/gift/apply/list", {
					current:1
				}).then(async res => {
					this.tableData=res.records
				        
				})
			},
			go_gifts(){	
				
               this.$router.push('/exchangeGifts')
			}
		}

	}
</script>

<style scoped="scoped" lang="scss">
	
	.nav {

		height: 200px;
		background: #3296fa;
		border: 1px solid #D6D6D6;

		.blocks {
			text-align: center;
			color: #fff;
			height: 180px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			.yellow {
				color: #fde720;
			}

			.green {
				color: #3ae03d;
				font-size: 54px;
			}

			.gray {
				color: #c9c8c8;
			}
		}


	}
</style>
